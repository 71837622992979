import $ from 'jquery';
import Aos from 'aos';

(function ($) {
  'use strict';

  const cfg = {
    scrollDuration: 800,
  };

  const $WIN = $(window);

  const ssPreloader = function () {
    $('html').addClass('ss-preload');

    $WIN.on('load', function () {
      $('html, body').animate({ scrollTop: 0 }, 'normal');

      $('#loader').fadeOut('slow', function () {
        $('#preloader').delay(300).fadeOut('slow');
      });

      // for hero content animations
      $('html').removeClass('ss-preload');
      $('html').addClass('ss-loaded');
    });
  };

  const ssMoveHeader = function () {
    const $hero = $('.s-hero'),
      $hdr = $('.s-header'),
      triggerHeight = $hero.outerHeight() - 170;

    $WIN.on('scroll', function () {
      let loc = $WIN.scrollTop();

      if (loc > triggerHeight) {
        $hdr.addClass('sticky');
      } else {
        $hdr.removeClass('sticky');
      }

      if (loc > triggerHeight + 20) {
        $hdr.addClass('offset');
      } else {
        $hdr.removeClass('offset');
      }

      if (loc > triggerHeight + 150) {
        $hdr.addClass('scrolling');
      } else {
        $hdr.removeClass('scrolling');
      }
    });
  };

  const ssMobileMenu = function () {
    const $toggleButton = $('.s-header__menu-toggle');
    const $headerContent = $('.s-header__content');
    const $siteBody = $('body');

    $toggleButton.on('click', function (event) {
      event.preventDefault();
      $toggleButton.toggleClass('is-clicked');
      $siteBody.toggleClass('menu-is-open');
    });

    $headerContent.find('.s-header__nav a, .btn').on('click', function () {
      if (window.matchMedia('(max-width: 900px)').matches) {
        $toggleButton.toggleClass('is-clicked');
        $siteBody.toggleClass('menu-is-open');
      }
    });

    $WIN.on('resize', function () {
      if (window.matchMedia('(min-width: 901px)').matches) {
        if ($siteBody.hasClass('menu-is-open'))
          $siteBody.removeClass('menu-is-open');
        if ($toggleButton.hasClass('is-clicked'))
          $toggleButton.removeClass('is-clicked');
      }
    });
  };

  const ssAOS = function () {
    Aos.init({
      offset: 100,
      duration: 600,
      easing: 'ease-in-out',
      delay: 300,
      once: true,
      disable: 'mobile',
    });
  };

  const ssSmoothScroll = function () {
    $('.smoothscroll').on('click', function (e) {
      const target = this.hash;
      const $target = $(target);

      e.preventDefault();
      e.stopPropagation();

      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: $target.offset().top,
          },
          cfg.scrollDuration,
          'swing'
        )
        .promise()
        .done(function () {
          window.location.hash = target;
        });
    });
  };

  const ssBackToTop = function () {
    const pxShow = 800;
    const $goTopButton = $('.ss-go-top');

    if ($WIN.scrollTop() >= pxShow)
      $goTopButton.addClass('link-is-visible');

    $WIN.on('scroll', function () {
      if ($WIN.scrollTop() >= pxShow) {
        if (!$goTopButton.hasClass('link-is-visible'))
          $goTopButton.addClass('link-is-visible');
      } else {
        $goTopButton.removeClass('link-is-visible');
      }
    });
  };

  const obuff = function () {
    const charsA = ['b', 'W', 'F', 'p', 'b', 'H', 'R', 'v'];
    const charsB = ['aGVsb', 'G9AYXZhcm', 'l0aWEuaHI='];

    Object.values(document.getElementsByClassName('obuffmail')).forEach(
      (item) => {
        item.innerText = atob(charsB.join(''));
        item.setAttribute(
          'href',
          [atob(charsA.join('')), atob(charsB.join(''))].join(':')
        );
      }
    );
  };

  (function ssInit() {
    ssPreloader();
    ssMoveHeader();
    ssMobileMenu();
    ssAOS();
    ssSmoothScroll();
    ssBackToTop();
    obuff();
  })();
})($);
